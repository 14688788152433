import React from 'react';
import {
	createBrowserRouter, 
	Route, 
	createRoutesFromElements,
	RouterProvider
} from "react-router-dom";

import ScrollToTop from './helper/ScrollToTop';
import { GlobalStyle } from "./components/common/styles/global.js";
import HomeOne from './HomeOne';
import HomeTwo from './HomeTwo';
import About from './pages/about/About';
import CourseGrid from './pages/courses/CourseGrid';
import CourseList from './pages/courses/CourseList';
import CourseDetails from './pages/courses/CourseDetails';
import Instructor from './pages/instructor/Instructors';
import InstructorDetails from './pages/instructor/InstructorDetails';
import Gallery from './pages/gallery/Gallery';
import Events from './pages/events/Events';
import EventDetails from './pages/events/EventsDetails';
import Login from './pages/account/Login';
import Register from './pages/account/Register';
import Contact from './pages/contact/Contact';
import Faq from './pages/faq/Faq';
import PageNotFound from './pages/404/PageNotFound';
import ComingSoon from './pages/comingsoon/ComingSoon';

import BlogClassic from './pages/blog/BlogClassic';
import BlogGrid from './pages/blog/BlogGrid';
import BlogDetails from './pages/blog/BlogDetails';
import Product from './pages/shop/Products';
import ProductDetails from './pages/shop/ProductDetails';
import Cart from './pages/shop/Cart';


const router = createBrowserRouter(
	createRoutesFromElements(
			<Route>
              

                <Route index element={<ComingSoon />} />
				{/* <Route exact path={`${process.env.PUBLIC_URL + "/"}`} element={<HomeOne/>} />
                
                <Route exact path={`${process.env.PUBLIC_URL + "/"}`} element={<HomeOne/>} />
                <Route path={`${process.env.PUBLIC_URL + "/home-two"}`} element={HomeTwo} />
                <Route path={`${process.env.PUBLIC_URL + "/about"}`} element={<About/>} />
                <Route path={`${process.env.PUBLIC_URL + "/course-grid"}`} element={CourseGrid} />
                <Route path={`${process.env.PUBLIC_URL + "/course-list"}`} element={CourseList} />
                <Route path={`${process.env.PUBLIC_URL + "/course-details"}`} element={CourseDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/instructor"}`} element={Instructor} />
                <Route path={`${process.env.PUBLIC_URL + "/instructor-details"}`} element={InstructorDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} element={Gallery} />
                <Route path={`${process.env.PUBLIC_URL + "/events"}`} element={Events} />
                <Route path={`${process.env.PUBLIC_URL + "/event-details"}`} element={EventDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/login"}`} element={Login} />
                <Route path={`${process.env.PUBLIC_URL + "/registration"}`} element={Register} />
                <Route path={`${process.env.PUBLIC_URL + "/contact"}`} element={Contact} />
                <Route path={`${process.env.PUBLIC_URL + "/faq"}`} element={Faq} />
                <Route path={`${process.env.PUBLIC_URL + "/404"}`} element={PageNotFound} />
                <Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} element={BlogClassic} />
                <Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} element={BlogGrid} />
                <Route path={`${process.env.PUBLIC_URL + "/blog-details"}`} element={BlogDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/products"}`} element={Product} />
                <Route path={`${process.env.PUBLIC_URL + "/product-details"}`} element={ProductDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/cart"}`} element={Cart} /> */}
			</Route>
	)
)

function App() {
	return (
		<>
		
			<RouterProvider router={router} />
			
		</>
		
	);
}

export default App;